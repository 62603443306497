export const sanitizeConfig = {
  allowedTags: [
    "h1",
    "h2",
    "h3",
    "blockquote",
    "dl",
    "dt",
    "li",
    "ol",
    "p",
    "del",
    "pre",
    "ul",
    "a",
    "br",
    "code",
    "sup",
    "input",
    "strong",
    "table",
    "img",
    "tbody",
    "td",
    "th",
    "hr",
    "thead",
    "tr",
    "em",
  ],
  allowedAttributes: {
    img: ["src", "alt"],
    input: ["type", "disabled", "checked"],
    a: ["href", "target"],
  },
  allowedSchemesAppliedToAttributes: ["src", "href"],
  allowedSchemes: ["http", "https"],
}
